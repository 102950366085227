import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BannerDiv = styled("div")`
  margin-bottom: 30px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  border-style: solid;
  border-width: 3px;
  padding: 2% 5% 2% 5%;
`
const SqBtn = styled("div")`
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;
  text-decoration: none;
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  position: relative;
  transition: all 300ms ease 0ms;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  :hover {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
`
const ThePage = () => {
  const data = useStaticQuery(graphql`
    query vtech1Query {
      top: file(relativePath: { eq: "relyon-workflow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="抗疫四式 第一式《噴！》 RelyOn Virkon 水性殺菌劑🇺🇸 ｜櫻花家居服務"
        description="抗疫四式 第一式《噴！》 RelyOn Virkon 水性殺菌劑🇺🇸 ｜櫻花家居服務"
      />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                    color: #fc9201;
                  `}
                >
                  第一式《噴！》 RelyOn Virkon 水性殺菌劑🇺🇸
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BannerDiv
                css={css`
                  border-color: #fc9201;
                  background-color: rgba(252, 146, 1, 0.25);
                `}
              >
                <p>
                  <Img fluid={data.top.childImageSharp.fluid} />
                </p>
                <p>
                  當RelyOn™ Virkon™藥劑噴於物品表面後,
                  當中的KHSO5（硫酸鉀5）作為一種強勁氧化劑，會將
                  NaCl（氯化鈉）氧化而產生 HOCl（hypochlorous acid,
                  次氯酸）。KHSO5（硫酸鉀5）及HOCl（次氯酸）兩者皆可氧化及分解細菌和病毒的蛋白部分，所以具強勁的殺菌性。
                </p>
                <p>
                  而
                  HOCl（次氯酸）氧化細菌和病毒後所產生的Cl-（氯離子）會與有機物結合成NaCl（氯化鈉），可以補充早前被KHSO5（硫酸鉀5）氧化而減少的NaCl（氯化鈉），使分解細菌和病毒的蛋白部分的科學反應可以長時間運作。噴射RelyOn™
                  Virkon™於物品表面後能長期消毒，阻止細菌和病毒傳播，亦經過第三方獨立研究所認證，能有效阻止類近武漢肺炎的病毒散播。早於2015
                  年，南韓MERS 爆發時已廣泛應用RelyOn™ Virkon™ 消毒。
                </p>
                <p>
                  配合專業高性能壓縮焗霧機械，可以在5分鐘內殺滅細菌、真菌及病毒，大範圍處理由百呎至萬呎空間。藥劑可壓縮成氣體，全面滲透於空氣中，對細菌、黴菌及真菌有極佳的對抗之效，獨特產品，於21國獲有專利。
                </p>
                <p>
                  配合專業機器噴槍，可以在5分鐘內殺滅細菌、真菌及病毒，大範圍處理由百呎至萬呎空間💪
                  <br />
                  👉醫院專用防疫產品，信心保證。
                  <br />
                  👉成份無刺激性，安全可靠。
                  <br />
                  👉一次性徹底消毒，事後不用清潔擦拭。
                  <br />
                  👉在21個國家取得專利。
                  <br />
                  👉能在短時間內消滅69種病毒 , 400多種細菌及1000多種真菌。
                  <br />
                  👉所有室內場所均可使用（包括辦公室、大廈大堂走廊）
                </p>
              </BannerDiv>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Link to="/櫻花消毒技術">
                <SqBtn
                  css={css`
                    color: #fc9201 !important;
                    border-color: #fc9201;
                  `}
                >
                  返回「櫻花消毒技術」
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第二式-hoci">
                <SqBtn
                  css={css`
                    color: #f900de !important;
                    border-color: #f900de;
                  `}
                >
                  第二式《抹！》 HOCI 次亜塩素酸🇯🇵
                </SqBtn>
              </Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第三式-PIE臭氧消毒裝置">
                <SqBtn
                  css={css`
                    color: #4691f8 !important;
                    border-color: #4691f8;
                  `}
                >
                  第三式《放！》 PIE 臭氧消毒裝置
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第四式-日本東芝光觸媒">
                <SqBtn
                  css={css`
                    color: #01dcbc !important;
                    border-color: #01dcbc;
                  `}
                >
                  第四式《抗！》日本東芝光觸媒
                </SqBtn>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/booking" className="button">
                  <b>立即預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/消毒案例" className="button">
                  <b>消毒案例</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
